<template>
  <div id="containerId" class="container">
    <!-- 封面 -->
    <img
      v-if="pageData && pageData.coverImg"
      :src="pageData.coverImg"
      class="coverImg"
    />
    <!-- 导航条容器 -->
    <div v-else class="navContainer">
      <div class="navTitle">测评报告详情</div>
    </div>
    <!-- 基本信息容器 -->
    <BaseInfoContainer
      :myDataObj="pageData"
      v-if="pageData"
      :isHiddenBtn="isHiddenBtn"
      :isExport="isExport"
      :reportType="2"
    />
    <!-- 工具介绍 -->
    <div class="dimensionResultContainer" v-if="pageData.overview">
      <TitleBar title="工具介绍" />
      <ContentBox
        :content="pageData.overview"
        :isBackGroundColor="true"
      />
    </div>
    <div
      class="itemResultContainer"
      v-if="pageData && pageData.totalResultList"
    >
      <div v-for="(item, index) in pageData.totalResultList" :key="index">
        <TitleBar :title="item.title" />
        <div class="itemBox">
          <div v-for="(items, indexs) in item.content" :key="indexs">
            <div
              class="subTitle"
              v-if="(items.type == 'subTitle' || items.type == 'text') && items.data.content"
            >
              <ContentBox :content="items.data.content | filterColorText" />
            </div>
            <!-- <div
              class="subTitle"
              v-if="items.type == 'subTitle'"
            >
              <DimensionTitle :content="items.data.content | filterColorText" />
            </div> -->
            <!-- <div
              class="subTitle"
              v-if="items.type == 'text'"
            >
              <ContentBox :content="items.data.content | filterColorText" />
            </div> -->

            <div class="tableType" v-if="items.type == 'table'">
              <el-table
                border
                :data="items.data.tableContent"
                style="width: 100%"
                :header-cell-style="{
                  background: '#eef1f6',
                  color: '#606266',
                }"
                :show-summary="items.data.isTotal == 1"
              >
                <el-table-column
                  align="center"
                  :prop="table.key"
                  :label="table.label"
                  v-for="(table, tableIndex) in items.data.header"
                  :key="tableIndex"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="chartsDisplayBox" v-if="items.type == 'charts'">
              <div class="dimensionChartView">
                <Charts :option="items.data.chartData.option" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="totalResultContainer"
      v-if="pageData && pageData.scienceObj && pageData.scienceObj.content"
    >
      <TitleBar :title="pageData.scienceObj.title" />
      <ContentBox
        :content="pageData.scienceObj.content | filterColorText"
        :isBackGroundColor="true"
      />
    </div>
    <!-- 整体建议 -->
    <div class="dimensionResultContainer" v-if="pageData.summary">
      <TitleBar title="整体建议" />
      <ContentBox
        :content="pageData.summary"
        :isBackGroundColor="true"
      />
    </div>
    <!-- 封底 -->
    <img
      v-if="pageData && pageData.backCoverImg"
      :src="pageData.backCoverImg"
      class="coverImg"
    />
  </div>
</template>
<script>
import { getTeamReportData } from "../../api/report";
import { mixins } from "../../utils/mixins";
// import DimensionTitle from "../../components/dimensionTitle.vue"
export default {
  mixins: [mixins],
  // components:{
  //   DimensionTitle
  // },
  data() {
    return {
      pageData: undefined,
    };
  },
  mounted() {
    getTeamReportData(this.queryParams).then((res) => {
      if (res.code == 200) {
        this.pageData = res.data;
        if (this.pageData.overview) {
          this.pageData.overview = this.pageData.overview.replace(/\n/g, "<br>");
        }
        if (this.pageData.scienceObj && this.pageData.scienceObj.content) {
          this.pageData.scienceObj.content = this.pageData.scienceObj.content.replace(/\n/g, "<br>");
        }
        if (this.pageData.summary) {
          this.pageData.summary = this.pageData.summary.replace(/\n/g, "<br>");
        }
        res.data.totalResultList.forEach((item) => {
          item.content.forEach((items) => {
            if (
              items.type == "table" &&
              items.data != undefined &&
              items.data &&
              items.data.isTotal != undefined &&
              items.data.isTotal
            ) {
              items.data.header.push({ label: "行合计", key: "rowCount" });
              items.data.tableContent.forEach((row) => {
                let rowCount = 0;
                for (const key in row) {
                  if (typeof +row[key] === "number" && !isNaN(row[key])) {
                    rowCount += +row[key];
                  }
                }
                row.rowCount = rowCount;
              });
            }
          });
        });
      }
    });
  },
  methods: {
    getData() {},
  },
};
</script>
<style lang="scss" scoped>
@import "~styles/commonPage.scss";
</style>